import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "add-company", fluid: "", tag: "section" } },
        [
          _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3",
              attrs: {
                color: "primary",
                icon: "mdi-office-building",
                title: _vm.page_title(),
              },
            },
            [
              _vm.edit
                ? _c(
                    VTabs,
                    {
                      on: { change: _vm.reset_alert },
                      model: {
                        value: _vm.tabs,
                        callback: function ($$v) {
                          _vm.tabs = $$v
                        },
                        expression: "tabs",
                      },
                    },
                    [
                      _c(VTab, [_vm._v(_vm._s(_vm.$t("basic")))]),
                      _vm.edit && _vm.roundsEnv === "FALSE"
                        ? _c(VTab, [
                            _vm._v(_vm._s(_vm.$t("briefing_permission"))),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _c("ManageCompanyBasic", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabs == 0,
                    expression: "tabs == 0",
                  },
                ],
                attrs: {
                  OriginalIndex: _vm.originalIndex,
                  OriginalCategory: _vm.originalCategory,
                  StockOptions: _vm.stocks,
                  company_prop: _vm.company,
                },
                on: { alert: _vm.show_alert, saved: _vm.basic_saved },
              }),
              _vm.edit && _vm.roundsEnv === "FALSE"
                ? _c("ManagePartnerView", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabs == 1,
                        expression: "tabs == 1",
                      },
                    ],
                    attrs: { company_prop: _vm.company },
                  })
                : _vm._e(),
              _vm.alert_msg != null
                ? _c(
                    VAlert,
                    {
                      staticClass: "ma-2",
                      attrs: { type: _vm.success ? "success" : "error" },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(_vm.alert_msg)) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }